<template>
  <div class="permission-update">
    <div class="permission-update__container mb-6">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation>
        <h1 class="mb-4">
          {{ $t('app.page.permissionUpdate.title') }}
        </h1>
        <v-row no-gutters>
          <v-col
            cols="12"
            md="4"
            class="pr-md-4">
            <div class="mb-2">
              {{ $t('app.label.firstName') }}
            </div>
            <v-text-field
              v-model="form.firstName"
              :rules="firstNameRules"
              :placeholder="$t('app.placeholder.firstName')"
              dense
              outlined
              required />
          </v-col>
          <v-col
            cols="12"
            md="4">
            <div class="mb-2">
              {{ $t('app.label.lastName') }}
            </div>
            <v-text-field
              v-model="form.lastName"
              :rules="lastNameRules"
              :placeholder="$t('app.placeholder.lastName')"
              dense
              outlined
              required />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            cols="12"
            md="4"
            class="pr-md-4">
            <div class="mb-2">
              {{ $t('app.label.email') }}
            </div>
            <v-text-field
              v-model="form.email"
              :rules="emailRules"
              :placeholder="$t('app.placeholder.email')"
              dense
              outlined
              required />
          </v-col>
          <v-col
            cols="12"
            md="4">
            <div class="mb-2">
              {{ $t('app.label.telephone') }}
            </div>
            <v-text-field
              v-model="form.phoneNo"
              :rules="phoneNoRules"
              :placeholder="$t('app.placeholder.telephone')"
              dense
              outlined
              required />
          </v-col>
        </v-row>
        <v-row
          no-gutters
          class="mb-6">
          <v-col
            cols="12"
            md="4">
            <div class="mb-2">
              {{ $t('app.label.role') }}
            </div>
            <v-select
              v-model="form.roleId"
              :items="roles"
              item-text="name"
              item-value="id"
              :disabled="user.meta.roleName !== 'admin'"
              dense
              outlined
              hide-details />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="mb-2">
              {{ $t('app.label.locations') }}
            </div>
            <v-data-table
              :loading="isLoading"
              :headers="headers"
              :items="items"
              height="385"
              hide-default-footer>
              <template v-slot:item.checkbox="{ item }">
                <v-checkbox
                  :input-value="checkLocation(item.id)"
                  :ripple="false"
                  style="width: 25px;"
                  class="mt-0 pt-0"
                  dense
                  hide-details
                  @change="selectLocation(item.id, $event)" />
              </template>
              <template v-slot:item.type="{ item }">
                <v-chip
                  :color="colorType(item)"
                  label
                  small>
                  {{ item.type }}
                </v-chip>
              </template>
            </v-data-table>
            <div class="d-flex justify-start mt-6">
              <v-pagination
                v-model="page"
                :length="totalPage"
                :total-visible="5" />
            </div>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <div class="text-right">
      <v-btn
        class="mr-4"
        text
        @click="cancel()">
        {{ this.$t('app.btn.cancel') }}
      </v-btn>
      <v-btn
        :loading="isSubmitting"
        color="primary"
        depressed
        @click="submit()">
        {{ this.$t('app.btn.save') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { location } from '@/mixins/location'
import AdminProvider from '@/resources/axios/providers/admin'

export default {
  name: 'PermissionUpdate',
  mixins: [location],
  data () {
    return {
      valid: true,
      isSubmitting: false,
      statusFilter: 'ACTIVE',
      headers: [
        { text: '', sortable: false, value: 'checkbox' },
        { text: this.$t('app.table.header.locationName'), sortable: false, value: 'name' },
        { text: this.$t('app.table.header.telephone'), sortable: false, value: 'phoneNo' },
        { text: this.$t('app.table.header.type'), sortable: false, value: 'type' },
        { text: this.$t('app.table.header.latitude'), sortable: false, value: 'latitude' },
        { text: this.$t('app.table.header.longitude'), sortable: false, value: 'longitude' },
        { text: this.$t('app.table.header.province'), sortable: false, value: 'province' },
        { text: this.$t('app.table.header.district'), sortable: false, value: 'district' },
        { text: this.$t('app.table.header.subDistrict'), sortable: false, value: 'subDistrict' },
        { text: '', sortable: false, value: 'options' }
      ],
      form: {
        id: null
      },
      selectedLocation: [],

      onkeypress: 'return event.charCode >= 48 && event.charCode <= 57',
      firstNameRules: [(v) => !!v || this.$t('app.validate.firstName')],
      lastNameRules: [(v) => !!v || this.$t('app.validate.lastName')],
      phoneNoRules: [(v) => !!v || this.$t('app.validate.telephone')],
      emailRules: [(v) => !!v || this.$t('app.validate.email')]
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      roles: 'local/roles'
    })
  },
  mounted () {
    this.getAdminById()
    this.getLocation()
  },
  methods: {
    ...mapActions({
      showErrorMessage: 'notification/showErrorMessage',
      closeErrorMessage: 'notification/closeErrorMessage'
    }),
    checkLocation (id) {
      return this.selectedLocation?.some((item) => item === id)
    },
    cancel () {
      this.$router.push({ name: 'Permission' })
    },
    async getAdminById () {
      try {
        const data = await AdminProvider.getAdminOne(this.$route.params?.permissionId)
        if (data) {
          this.form = this.$_.cloneDeep(data)
          const locations = []
          this.form.locations.forEach((item) => {
            locations.push(item.id)
          })
          this.selectedLocation = locations
        }
      } catch (error) {
        console.error(error)
      }
    },
    async submit () {
      if (this.isSubmitting) { return }
      const valid = this.$refs.form.validate()
      if (valid) {
        this.isSubmitting = true
        const res = await AdminProvider.updateAdmin(this.$route.params.permissionId,
        {
          roleId: this.form.roleId,
          roleName: this.form.roleName,
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          email: this.form.email,
          phoneNo: this.form.phoneNo,
          locations: this.selectedLocation,
          status: this.form.status
        })
        if (res) {
          this.showErrorMessage({
            message: this.$t('app.alert.update'),
            type: 'success'
          })
          setTimeout(() => {
            this.closeErrorMessage()
          }, 2000)
          this.isSubmitting = false
          this.cancel()
        }
      }
    },
    selectLocation (id, value) {
      if (value) {
        this.selectedLocation.push(id)
      } else {
        const index = this.selectedLocation.findIndex((item) => item === id)
        this.selectedLocation.splice(index, 1)
      }
    }
  }
}
</script>

<style scoped>
  .permission-update {

  }
  .permission-update__container {

  }
</style>
