import HttpRequest from '@/resources/axios/core/http-request'
import store from '@/store'

class AdminProvider extends HttpRequest {
  getAdminList = (params) => {
    this.setHeader({
      key: 'Authorization',
      value: `Bearer ${store.getters['auth/user'].accessToken}`
    })
    return this.get(
      `/admins?page=${params.page}&limit=${params.limit}&status=ACTIVE&eagerLoad=Locations&orderBy=id&sortBy=desc${params.filter}`
    )
  }

  getAdminOne = (param) => {
    this.setHeader({
      key: 'Authorization',
      value: `Bearer ${store.getters['auth/user'].accessToken}`
    })
    return this.get(`/admins/${param}?eagerLoad=Locations`)
  }

  updateAdmin = (param, payload) => {
    this.setHeader({
      key: 'Authorization',
      value: `Bearer ${store.getters['auth/user'].accessToken}`
    })
    return this.put(`/admins/${param}`, payload)
  }

  deleteAdmin = (param) => {
    this.setHeader({
      key: 'Authorization',
      value: `Bearer ${store.getters['auth/user'].accessToken}`
    })
    return this.delete(`/admins/${param}`)
  }
}

export default new AdminProvider()
